.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal_content {
  width: 80%;
  max-width: 500px;
  /* background-color: #f0f; */
}

@media (max-width: 1250px) {
  /* … */
}

.modal_header,
.modal_footer {
  padding: 10px;
}

.modal_title {
  margin: 0;
}

.modal_body {
  padding: 10px;
}
