@font-face {
  font-family: "Tiempos Headline";
  src: url(../src/Assets/fonts/TiemposHeadline-Black/TiemposHeadline-Black.ttf)
    format("truetype");
  /* font-style: normal; */
  /* font-weight: 400; */
}

@font-face {
  font-family: "ApercuProBoldNormal";
  src: local("apercu_bold_pro"),
    url(../src/Assets/fonts/apercu-pro-fonts/apercu_bold_pro.otf)
      format("opentype");
  /* font-style: normal; */
  /* font-weight: 700; */
}

@font-face {
  font-family: "ApercuProBoldItalic";
  src: local("apercu_bold_italic_pro"),
    url(../src/Assets/fonts/apercu-pro-fonts/apercu_bold_italic_pro.otf)
      format("opentype");
  /* font-style: italic; */
  /* font-weight: 700; */
}

@font-face {
  font-family: "ApercuProMediumNormal";
  src: local("apercu_medium_pro"),
    url(../src/Assets/fonts/apercu-pro-fonts/apercu_medium_pro.otf)
      format("opentype");
  /* font-style: normal; */
  /* font-weight: 500; */
}

@font-face {
  font-family: "ApercuProMediumItalic";
  src: local("apercu_medium_italic_pro"),
    url(../src/Assets/fonts/apercu-pro-fonts/apercu_medium_italic_pro.otf)
      format("opentype");
  /* font-style: italic; */
  /* font-weight: 500; */
}

@font-face {
  font-family: "ApercuProRegularNormal";
  src: local("apercu_regular_pro"),
    url(../src/Assets/fonts/apercu-pro-fonts/apercu_regular_pro.otf)
      format("opentype");
  /* font-style: normal; */
  /* font-weight: 400; */
}

@font-face {
  font-family: "ApercuProRegularItalic";
  src: local("apercu_regular_italic_pro"),
    url(../src/Assets/fonts/apercu-pro-fonts/apercu_regular_italic_pro.otf)
      format("opentype");
  /* font-style: italic; */
  /* font-weight: 400; */
}

*,
:before,
:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

:root {
  --clr-pry: rgba(251, 189, 41, 1);
  --clr-pry-inactive: rgba(251, 189, 41, 0.5);
  --clr-dark: rgba(26, 25, 30, 1);
  --clr-sky-blue: #e8f2ee;
  --clr-sky-pink: rgba(183, 123, 47, 0.1);
  --clr-gray-light: #f8f8f8;
  --clr-transparent: rgba(0, 0, 0, 0.2);
  --clr-placeholder: #c4c4c4;
  --clr-danger: #d70000;

  --clr-light: #fff;
  --clr-black: #000;
}

html,
body {
  font-family: "ApercuProRegularNormal";
  line-height: 1;
  font-size: 1rem;
  font-style: bold;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.nobar::-webkit-scrollbar {
  display: none;
}

.nobar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a,
div,
section {
  padding: 0;
  margin: 0;
}

button {
  font-family: "ApercuProMediumNormal";
  border: none;
  background: none;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.error {
  color: red;
}

form .message {
  margin: 0.1875rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  min-height: 0.75rem;
  text-align: center;
}

.message.error:first-letter {
  text-transform: uppercase;
}

.password_visibility {
  background: none;
  border: none;
  position: absolute;
  cursor: pointer;
  right: 4%;
  /* bottom: 20%; */
  top: 50%;
  transform: translateY(0, -50%);
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

#amount_requested.form_input {
  /* background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30"><text x="5" y="19" style="font: 16px Arial;">NGN</text></svg>')
    no-repeat; */
  box-sizing: border-box;
  /* padding-left: 50px; */
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
